import { graphql, Link } from "gatsby"
import React from "react"
import useRoutePath from "../../../hooks/useRoutePath"
import useWebsite from "../../../hooks/useWebsite"
import { slugify } from "../../../utils"
import Button from "../../atoms/Button"
import type { HarviaBackgroundColor } from "../../layouts/BgColor"
import BackgroundMedia from "../../molecules/BackgroundMedia"
import type { MediaTextBlockLayout } from "../../molecules/tileable-blocks/MediaTextBlock"
import MediaTextBlock from "../../molecules/tileable-blocks/MediaTextBlock"
//import * as styles from "./DatoCtaToProductFinder.module.scss"

const DatoCtaToProductFinder = (props: Queries.DatoCtaToProductFinderFragment) => {
  //console.log('Rendering DatoCtaToProductFinder', props)
  const getRoutePath = useRoutePath()
  const path = getRoutePath(useWebsite().productRootRoute?.originalId)

  return (
    <MediaTextBlock
      id={slugify(props.title)}
      layout={props.layout as MediaTextBlockLayout}
      contentBackground={props.textBackground as HarviaBackgroundColor}
      media={props.image && <BackgroundMedia {...props.image} />}
      content={
        <>
          <h3>{props.title}</h3>
          <p style={{ flexGrow: 1 }}>{props.text}</p>
          {path && (
            <Link to={path + props.searchQuery}>
              <Button label={props.linkText} tabIndex={-1} />
            </Link>
          )}
        </>
      }
    />
  )
}

export default DatoCtaToProductFinder

export const query = graphql`
  fragment DatoCtaToProductFinder on DatoCmsCtaToProductFinder {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    searchQuery
    text
    title
    linkText
    image {
      ...DatoImageDefault
    }
    textBackground
    layout
  }
`
